.video-container {
  height: 600px;
  width: 100%;
  /* max-width: 1000px; */

  position: relative;
  overflow: hidden;
}

video {
  object-fit: cover;
  position: absolute;
  height: 600px;
  width: 100%;
  top: 0;
  left: 0;
}

.video-container:after {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(black, 0.2);
  z-index: 1;
}

.intro {
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: 'Karla', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: white;
  letter-spacing: 2px;
}

.desc {
  font-family: 'Old Standard TT', serif;
  font-weight: 400;
  font-size: 1.2rem;
  color: white;
  margin-top: 10px;
  letter-spacing: 2px;
}

.callout {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 10;
  width: 50%;
  margin: 2rem 1rem ;
  bottom: 100px;
}

@media (max-width: 425px) {
  .intro {
    font-size: 1rem;
  }
  .desc{
    font-size: 0.8rem;
  }
  .callout{
    z-index: 0;
  }
}