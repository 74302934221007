.products {
  padding: 2rem 0rem;
  margin-top: 2rem;
}
.product-title {
  font-size: 1.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #00796b;
  text-align: center;
  letter-spacing: 2px;
}
.product-content {
  font-size: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #00796b;
  text-align: center;
  letter-spacing: 2px;
  margin-top: 20px;
}
.img-container {
  display: flex;
  flex-wrap: wrap;
  /* padding: 5px; */
  justify-content: center;
  margin-top: 2rem;
  gap: 10px;
  /* flex: 1 1 160px; */
  align-items: center;
}

img{
    width: 400px;
}

@media (max-width: 425px) {
    img{
        width: 300px;
    }
}