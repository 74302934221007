.footer {
  /* position: fixed;
  left: 0;
  bottom: 0; */
  /* width: -webkit-fill-available; */
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -15px 10px -20px
}

.copywright {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  color: #00796b;
  font-weight: 500;
}

.social-media {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}

.media-icon {
  color: #00796b;
  cursor: pointer;
}

@media screen and (max-width: 425px) {
  .footer {
    display: block;
    padding: 1rem 1rem;
  }
  .copywright {
    display: flex;
    column-gap: 1rem;
    margin-top: 10px;
    font-size: 0.8rem;
  }
}
