.contact {
  padding: 2rem 0rem;
  margin-top: 2rem;
}
.contact-title {
  font-size: 1.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #00796b;
  text-align: center;
  letter-spacing: 2px;
}
.contact-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 2rem;
  column-gap: 2rem;
}
.contact-item {
  font-size: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 2rem;
}
.contact-details {
  width: 50%;
}
.contact-form {
  width: 50%;
}
.label-title {
  font-size: 1rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #00796b;
  letter-spacing: 2px;
}
input {
  margin-top: 10px;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  width: 80%;
  border: 1px solid #00796b;
}
input:focus {
  outline: none;
}

textarea {
  margin-top: 10px;
  padding: 0.5rem 0 0.5rem 0.5rem;
  width: 100%;
  height: 100px;
  border: 1px solid #00796b;
}
.input-label {
  margin-top: 20px;
}
button {
  padding: 0.5rem 4rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 600;
  color: #00796b;
  background-color: white;
  border: 2px solid #00796b;
  border-radius: 10px;
}

@media screen and (max-width: 425px) {
  .contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
    row-gap: 20px;
  }
  .contact-details {
    width: 100%;
  }
  .contact-form {
    width: 100%;
  }
  .contact-item {
    font-size: 0.8rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 2rem;
  }
  button {
    padding: 0.5rem 2rem;
    font-size: 1rem;
  }
  textarea {
    width: 100%;
  }
}
