@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem;
  background-color: white;
  /* box-shadow: 0px 15px 10px -20px; */
}

.list {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  list-style-type: none;
}

.logo-img{
  display: flex;
  align-items: center;
  animation: fadeIn 1s ease-in-out;
}

.list-item {
  cursor: pointer;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  color: #00796b;
  padding: 0.5rem 1rem;
  font-weight: 500;
  animation: fadeIn 1s ease-in-out;
}

.list-item:hover{
  color: white;
}

h3{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* font-size: 36px; */
  color: #00796b;
  cursor: pointer;
  animation: fadeIn 1s ease-in-out;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover{
  background-color: #00796b;
  border-radius: 10%;
}

.logo {
  width: 100px;
  height: auto;
  cursor: pointer;
}

@media screen and (max-width: 425px) {
  .list-item {
    font-size: 1rem;
    padding: 0;
  }

  .header {
    padding: 1rem 1rem;
    display: block;
  }

  .list{
    justify-content: space-evenly;
  }

  ul{
    padding: 0;
  }

  a:hover{
    background-color: #004d00;
    border-radius: 10%;
  }
}