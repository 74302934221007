.about {
  padding: 2rem 0rem;
  margin-top: 2rem;
}
.about-title {
  font-size: 1.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  color: #00796b;
  text-align: center;
  letter-spacing: 2px;
}

img {
  max-width: 100%;
  height: 400px;
  object-fit: cover;
}

.about-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 2rem;
  margin-top: 2rem;
}

.about-image {
  width: 50%;
  text-align: center;
}
.about-desc {
  width: 50%;
}

.content-title {
  font-size: 1.5rem;
  font-family: "Old Standard TT", serif;
  font-weight: 500;
  letter-spacing: 2px;
  color: #00796b;
}

.content {
  font-size: 1rem;
  font-family: "Old Standard TT", serif;
  font-weight: 500;
  letter-spacing: 2px;
  margin-top: 20px;
  line-height: 1.8;
}

@media screen and (max-width: 425px) {
  .about {
    padding: 1rem;
    text-align: center;
  }
  .about-content {
    display: block;
    padding: 0;
    text-align: center;
  }
  .about-desc {
    width: 100%;
    margin-top: 20px;
  }
  .about-image {
    width: 100%;
    text-align: center;
  }
  img {
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .content {
    font-size: 0.8rem;
  }
}
